import $ from 'cash-dom'

$(window).on('load', () => {
  $('.section iframe').each(function() {
    $(this).wrap('<div class="embed"></div>')
  })

  $('.product-content-inner img').each(function() {
    $(this).attr('data-action', 'zoom')
  })
  
  $('.case-content-inner img').each(function() {
    $(this).attr('data-action', 'zoom')
  })
  
  $('.knowledge_center-content img').each(function() {
    $(this).attr('data-action', 'zoom')
  })
})